import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function Home() {
  return (
    <div style={{background: "linear-gradient(0deg, #1a1e69 0%, #330061 100%)", color: 'white'}}>
      <div style={{
        backgroundImage: "url('https://deadlinedemo.vercel.app/static/bg-66388515cdee81def8dee36761115e0d.png')", 
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
        }}
        >
        <div style={{height: '12vh', width: '60vw', backgroundImage: "url('/logo.png')", margin: '0 auto', backgroundPosition: 'center',backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
        </div>
        <div style={{margin: '0 auto', borderRadius: 20, height: 'calc(88vh)'}}>
          <div style={{padding:20, margin: 10, marginTop: 0}}>
            <h2 style={{textAlign: 'center'}}>Learn - Eastman Enterprises</h2>
            <h3 style={{textAlign: 'center', marginBottom: 40, marginTop: 20}}>We are launching soon!</h3>
            <Typer
              heading="_"
              dataText={[  
                '🚀 Get ready to unlock your inner coding genius! 🚀',
                '🎓 Welcome to our digital playground where ones and zeroes come to party! 🎓',
                '🌟 Something epic is brewing! 🌟',
                '👩‍💻 Calling all future code wizards and digital dynamos! 👨‍💻',
                "💡 Brace yourselves for an e-learning experience that'll make your brain cells do the happy dance! 💡",
                '🔥 Ready to dive deep into the matrix of programming fundamentals? 🔥',
                '💻 Get set to conquer the digital universe with our Introduction to Web class! 💻',
                '🌐 Frontend frameworks, prepare to meet your match! 🌐',
                '⚙️ Backend frameworks, consider yourself officially challenged! ⚙️',
                '🎉 Prepare for mind-blowing lessons, epic challenges, and a boatload of virtual high-fives! 🎉',
                '🔜 Stay tuned! The future of your coding journey is just around the corner! 🔜'
              ]}
            />
            <h3 style={{textAlign: 'center', marginBottom: 0, marginTop: 30}}>Registration opens in</h3>
          </div>
          <Clock deadline={"03-01-2024"}/>
          <div style={{padding:20, margin: 20, marginTop: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{
              textAlign: 'center',
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 40,
              paddingRight: 40,
              fontSize: 16,
              borderRadius: 16,
              maxWidth: '80vw',
              border: '1.5px solid #330061',
              background: 'linear-gradient(135deg, #330061 0%, #1a1e69 100%)'}}>
                <p><b>
                Reach out to us!</b> <br/><br/>
                
                <a aria-label="Chat on WhatsApp" href="https://wa.me/923044243496"> 
                <img alt="Chat on WhatsApp" src="/WhatsAppButtonWhiteLarge.png" width={'200vw'}/>
                </a><br/>
                <b>
                {"Muhammad Ehsan                  "}</b>
                <br/>
                Email: ehsan@eastmanenterprises.com<br/>
                <br/>
                <a aria-label="Chat on WhatsApp" href="https://wa.me/923314488908"> 
                <img alt="Chat on WhatsApp" src="/WhatsAppButtonWhiteLarge.png" width={'200vw'}/>
                </a><br/>
                <b>
                {"Khan Muhammad Riaz Bin Tariq    "}<br/></b>
                Email: khan@eastmanenterprises.com<br/>
                </p>
              </div>
          </div>
          <div>
            <p style={{textAlign: 'center', padding:'10px 20px'}}>Copyright 2024 by Eastman Innovations (PVT Limited). All rights reserved</p>
          </div>
        </div>
        
      </div>
    </div>
  );
}

const zerofill = num => ((num < 10 && num >= 0) ? `0${num}` : num);

const SvgCircle = (props) => {
  const { className, done, max, radius, stroke, strokeWidth } = props
  const size = (radius + strokeWidth) * 2
  const length = Math.ceil(2 * radius * Math.PI)
  const remainingLength = length - (Math.ceil(2 * radius * Math.PI) * (done / max))
  return (
    <svg 
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle 
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
            transition: 'stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7)'
          }}
          r={radius}
          cx={radius + strokeWidth} 
          cy={radius + strokeWidth} 
          stroke={stroke}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none" 
        />
        <circle 
          className="circle--bg"
          r={radius} 
          cx={radius + strokeWidth}
          cy={radius + strokeWidth} 
          stroke="rgba(0, 0, 0, .1)"
          strokeLinecap="round"
          strokeWidth={strokeWidth} 
          fill="none" 
        />
      </g>
    </svg>
  )
}

SvgCircle.defaultProps = {
  done: 0,
  max: 24,
  radius: 72,
  stroke: '#a04aed',
  strokeWidth: 8,
}

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
  UNSAFE_componentWillMount() {
    this.getTimeUntil(this.props.deadline)
  }
  componentDidMount() {
    this.timerId = setInterval(() => this.getTimeUntil(this.props.deadline), 1000)
  }
  componentWillUnmount() {
    clearInterval(this.timerId)
  }
  getTimeUntil(deadline) {
    const formattedDate = moment(deadline, "MM-DD-YYYY")
      const timeDifference = Date.parse(formattedDate) - Date.now();
  
      const nonNegativeTimeDifference = Math.max(0, timeDifference);

    const seconds = Math.floor(nonNegativeTimeDifference / 1000 % 60);
    const minutes = Math.floor(nonNegativeTimeDifference / (1000 * 60) % 60);
    const hours = Math.floor(nonNegativeTimeDifference / (1000 * 60 * 60) % 24);
    const days = Math.floor(nonNegativeTimeDifference / (1000 * 60 * 60 * 24));
  
    this.setState({ days, hours, minutes, seconds })
  }
  render() {
    return (
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}>
        <div className="clock__display" style={{
            height: '160px',
            position: 'relative',
            width: '160px'
        }}>
          <SvgCircle className="clock__circle" max={365} done={this.state.days} />
          <div className="clock__text clock__text--days" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            textAlign: 'center',
            top: 0,
            width: '100%'
          }}>
            <span className="clock__amount" style={{fontSize: '3.75rem'}}>{zerofill(this.state.days)}</span>
            <span className="clock__unit" style={{fontSize: '0.75rem'}}>days</span>
          </div>
        </div>
        <div className="clock__display" style={{
            height: '160px',
            position: 'relative',
            width: '160px'
        }}>
          <SvgCircle max={24} done={this.state.hours} />
          <div className="clock__text clock__text--hours" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            textAlign: 'center',
            top: 0,
            width: '100%'
          }}>
            <span className="clock__amount" style={{fontSize: '3.75rem'}}>{zerofill(this.state.hours)}</span>
            <span className="clock__unit" style={{fontSize: '0.75rem'}}>hours</span>
          </div>
        </div>
        <div className="clock__display" style={{
            height: '160px',
            position: 'relative',
            width: '160px'
        }}>
          <SvgCircle max={60} done={this.state.minutes} />
          <div className="clock__text clock__text--minutes" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            textAlign: 'center',
            top: 0,
            width: '100%'
          }}>
            <span className="clock__amount" style={{fontSize: '3.75rem'}}>{zerofill(this.state.minutes)}</span>
            <span className="clock__unit" style={{fontSize: '0.75rem'}}>minutes</span>
          </div>
        </div>
        <div className="clock__display" style={{
            height: '160px',
            position: 'relative',
            width: '160px'
        }}>
          <SvgCircle max={60} done={this.state.seconds} />
          <div className="clock__text clock__text--seconds" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            textAlign: 'center',
            top: 0,
            width: '100%'
          }}>
            <span className="clock__amount" style={{fontSize: '3.75rem'}}>{zerofill(this.state.seconds)}</span>
            <span className="clock__unit" style={{fontSize: '0.75rem'}}>seconds</span>
          </div>
        </div>
      </div>
    )
  }
}


const TYPING_SPEED = 100;
const DELETING_SPEED = 30;
class Typer extends React.Component {

  state = {
    text: '',
    isDeleting: false,
    loopNum: 0,
    typingSpeed: TYPING_SPEED
  }

  componentDidMount() {
    this.handleType();
  }

  handleType = () => {
    const { dataText } = this.props;
    const { isDeleting, loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting ? fullText.substring(0, text.length - 1) :  fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? DELETING_SPEED : TYPING_SPEED
    });

    if (!isDeleting && text === fullText) {     
      setTimeout(() => this.setState({ isDeleting: true }), 1000);   
    } else if (isDeleting && text === '') {
      this.setState({
        isDeleting: false,
        loopNum: loopNum +  Math.floor(Math.random() * dataText.length)
      });      
    }

    setTimeout(this.handleType, typingSpeed);
  };

  render() {
    return (
      <h1 style={{textAlign: 'center', fontFamily: "'VT323', monospace"}}>
        <span>{ this.state.text } {this.props.heading}</span>
        <span id="cursor"></span>
      </h1>
    );
  }
}